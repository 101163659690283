<template>
  <div>
    <page-toolbar title="Update Root Cause Analysist Fishbone Diagram"></page-toolbar>

    <v-container>
      <page-title :title="cpar_header.document_no_">
        <template slot="action">
          <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
          <v-btn :loading="loading_submit" class="mx-1" @click="submitDocument()" color="primary" v-if="showSubmitBtn">Submit</v-btn>
        </template>
      </page-title>

      <v-row>
        <v-col cols="12">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :isMinimize="false">
            <v-row>
              <v-col cols="12">
                <v-row class="mb-3">
                  <v-col cols="12" md="6">
                    <v-text-field filled label="Document No." v-model="cpar_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Document Date" v-model="cpar_header.document_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Area Code" v-model="cpar_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Document Type" v-model="cpar_header.document_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field filled label="Created By" v-model="cpar_header.created_by_username" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Created Date" v-model="cpar_header.created_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Status" v-model="cpar_header.status" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Sub Status" v-model="cpar_header.substatus" hide-details outlined dense class="mb-2" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="6">
                    <h5 for="">PIC</h5>
                    <ol tile class="mx-auto" v-if="!loading">
                      <li v-for="(pic, i) in cpar_pic.filter((item) => { return item.proc == 'update-root-cause-analysis' })" :key="i">
                        <app-user-info :username="pic.username">{{ pic.username }}</app-user-info>
                        <v-tooltip bottom v-if="pic.submitted">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                              <v-icon size="large" color="green">mdi-check-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Submitted</span>
                        </v-tooltip>

                      </li>
                    </ol>
                  </v-col>
                </v-row>
                <v-btn @click="redirect('Administration.CPAR.Detail', { id: cpar_header.id })" text outlined>View Detail</v-btn>
              </v-col>
            </v-row>
          </card-expansion>
          <card-expansion :isMinimize="false" title="3. Root Cause Analysist Fishbone Diagram" class="mb-3">
            <UpdateAnalysis :cpar_header_id="$route.query.id"></UpdateAnalysis>
          </card-expansion>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UpdateAnalysis from "./components/UpdateAnalysis";
// import UserInfo from "@/components/user/UserInfo.vue";
export default {
  components: {
    UpdateAnalysis,
    // UserInfo,
  },
  data() {
    return {
      id: null,
      loading: false,
      loading_submit: false,
      cpar_header: {},
      cpar_pic: [],
    };
  },
  computed: {
    showSubmitBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;

      if (!this.inArray(this.cpar_header.substatus, ["on-process"])) return false;

      for (var i = 0; i < this.cpar_pic.length; i++){
        if(
          (this.cpar_pic[i].proc == 'update-root-cause-analysis') &&
          (this.cpar_pic[i].username == this.$store.state.loginInfo.username) &&
          (this.cpar_pic[i].submitted == true)
        ) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail();
    },
    async getDetail() {
      this.loading = true;

      this.cpar_header = {};

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: false,
      };
      await this.$axios
        .get("cpar/detail", config)
        .then((res) => {
          this.cpar_header = res.data.data.cpar_header;
          this.cpar_pic = res.data.data.cpar_pic;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async submitDocument() {
      const post = async () => {
        this.loading_submit = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("cpar_header_id", this.cpar_header.id);
        await this.$axios
          .post("cpar/submit-cause", formData)
          .then((res) => {
            this.loading_submit = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_submit = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Are you sure?<br>You will no longer be able to edit after pressing the submit button.", post);
    },
  },
  mounted() {
    this.id = parseInt(this.$route.query.id);

    this.getDetail(true);
  },
};
</script>
